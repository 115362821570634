import _ from "lodash";
import L from "lodash/fp";
import inject from "scripts/ioc/inject";
import BaseView from "scripts/views/baseView";
import templateFacetedSearchSummaryMenu from "templates/search/facetedSearchSummaryMenu.hbs";
import { facetParamOrder, searchFields } from "./facetMapper";
import { getCurrentFragment } from "../../utils/routerHelpers";

const chan = Backbone.Radio.channel;

class FacetedSearchSummaryMenuView extends BaseView {
  constructor(options, i18nextService = inject("i18nextService")) {
    super(options);

    this.i18nextService = i18nextService;

    this.sortField = "default";
  }

  get template() {
    return templateFacetedSearchSummaryMenu;
  }

  get id() {
    return "bb-faceted-search-summary-menu";
  }

  get className() {
    return "faceted-search-summary-menu";
  }

  get events() {
    return {
      "click #bb-faceted-search-refine-button": "onClickRefineButton",
      "click #bb-faceted-search-facet-menu-toggle": "setInitialSearch",
      "click #bb-faceted-search-grid-view-link": "onClickGridView",
      "click #bb-faceted-search-list-view-link": "onClickListView",
      "click #bb-faceted-search-menu-term-link": "searchTermClicked",
      "keyup #bb-faceted-search-menu-term-link": "searchTermKeyup",
      "click .faceted-search-sort-option": "sortOptionClicked",
    };
  }

  update() {
    this.render();
  }

  render() {
    this.destroyTooltips();

    const searchField = this.getSearchField();
    const termsMap = this.getTermsMap();
    const totalFilters = this.getTotalAppliedTerms(searchField, termsMap);

    this.$el.html(
      this.template({
        i18n: this.getTranslations(),
        hasResults: this.collection.totalRecords > 0,
        totalRecords: this.i18nextService.formatNumber(this.collection.totalRecords),
        isListView: this.model.get("presentationType") === "list",
        searchTerm: searchField ? searchField.term : "*",
        sortField: this.sortField,
        showFilterCount: totalFilters > 0,
        totalFilters: totalFilters,
      }),
    );

    if (this.collection.totalRecords === undefined) {
      $(".faceted-search-summary-menu-total-records").addClass("placeholder-text placeholder-header");
    }

    this.initTooltips();
    this.setTotalRecordDirection();
  }

  getTranslations() {
    const { search } = this.i18nextService.getGlossary();
    const { gridView, listView, toggleFilter } = L.get(["button"], this.i18nextService.getCommon());

    return {
      gridView,
      listView,
      search,
      toggleFilter,
    };
  }

  getSearchField() {
    //assuming single search field - different from facets
    const queryParams = this.model.get("queryParams");
    const field = _.flow([_.partial(_.intersection, searchFields), _.partialRight(_.nth, 0)])(Object.keys(queryParams));

    const term = queryParams[field];

    if (term) {
      const isFullText = queryParams["full-text"] === "true";
      const iapSelectOnly = queryParams._iap;
      return { field, term, isFullText, iapSelectOnly };
    }
  }

  getTermsMap() {
    const queryParams = this.model.get("queryParams");
    const termsMap = new Map();

    Object.keys(queryParams).forEach(facetParam => {
      //key: facetParam, value: Array of termCodes
      if (!searchFields.includes(facetParam) && facetParamOrder.includes(facetParam)) {
        if (facetParam === "_pd") {
          termsMap.set(facetParam, queryParams[facetParam].split(" OR "));
        } else {
          termsMap.set(
            facetParam,
            queryParams[facetParam].split('" OR "').map(term => term.replace(/"/g, "")),
          );
        }
      }
    });

    return termsMap;
  }

  getTotalAppliedTerms(searchField, termsMap) {
    if (searchField) {
      const selectedTerms = Array.from(termsMap.values());

      // Check if titleOnly or fullText facets are selected
      const { field, isFullText, iapSelectOnly } = searchField;
      let total = field === "t" || isFullText ? 1 : 0;
      total += iapSelectOnly ? 1 : 0;

      const totalAppliedTerms = selectedTerms.reduce((total, selectedTerm) => total + selectedTerm.length, total);

      return totalAppliedTerms;
    }
  }

  searchTermClicked() {
    this.trigger("showSearchTermModal");
    return false;
  }

  searchTermKeyup(e) {
    if (e.key === "Enter") {
      this.trigger("showSearchTermModal");
      return false;
    }
  }

  sortOptionClicked(event) {
    this.sortField = event.target.value;
    if (this.sortField !== "default") {
      this.trigger("sort", this.sortField);
    } else {
      this.trigger("sort", null);
    }
  }

  findCount(facetValues, code) {
    const info = facetValues.find(o => o.code === code);

    if (info) {
      return parseInt(info.count, 10);
    } else {
      return 0;
    }
  }

  initTooltips() {
    this.$el.find('[data-toggle="tooltip"]').tooltip({
      placement: "bottom",
      trigger: "hover",
      delay: 350,
    });
  }

  destroyTooltips() {
    this.$el.find('[data-toggle="tooltip"]').tooltip("destroy");
  }

  setTotalRecordDirection() {
    const currentLngDir = this.i18nextService.getCurrentLngDir();
    $(".faceted-search-summary-menu-results-region").css("direction", currentLngDir);

    if (currentLngDir === "rtl") {
      $(".faceted-search-summary-menu-total-records").css("padding-left", 10);
    }
  }

  getBaseUrl() {
    return `/search-results/${encodeURIComponent(this.model.get("query"))}`;
  }

  setInitialSearch() {
    chan("facetMenu").reply("toggle", getCurrentFragment());
  }

  onClickGridView() {
    $("#bb-faceted-search-list-view-link").removeClass("hidden");
    $("#bb-faceted-search-grid-view-link").addClass("hidden");

    this.model.set("presentationType", "masonry");
  }

  onClickListView() {
    $("#bb-faceted-search-grid-view-link").removeClass("hidden");
    $("#bb-faceted-search-list-view-link").addClass("hidden");

    this.model.set("presentationType", "list");
  }

  close() {
    this.destroyTooltips();
    super.close();
  }
}

export default FacetedSearchSummaryMenuView;
